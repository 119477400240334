@import "App.scss";

.textArea {
  padding: 16px 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  background-color: #fff;
  &.strongDark {
    background-color: $color-form-elm-bg-dark;
    border: $border-form-elm-dark;
    fieldset {
      border: none;
    }
    textarea {
      color: #fff;
    }
  }
  &.xl {
    height: 300px;
    font-size: 18px;
  }
}
