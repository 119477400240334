@import "App.scss";

.container {
  flex-grow: 1;
  min-width: 400px;
  height: 80px;
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid #d2d2d2;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 14px;

  .leftSec {
    display: flex;
    column-gap: 12px;
    flex-grow: 1;
    align-items: center;

    .rowLine {
      display: inline-block;
      width: 100%;
      height: 9px;
      border-radius: 5;
      background-color: #e5e5e5;
    }
  }
  .rightSec {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }
}

.infoIconContainer {
  cursor: pointer;
  height: 24px;
}

.tooltipContent {
  width: 523px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  color: #ffffff;

  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .description {
    color: #cdcdcd;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 8px;
  }
}

.usedCountNumber {
  @include BigTitleBoldText;
  color: $color-red-dark;
  min-width: 20px;
}
