.tooltipContainer {
  position: relative;
  display: inline-flex;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;

  // Fade in animation
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
}

// Placement variations
.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);

  &::before {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px 6px 0;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent;
  }
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(8px);

  &::before {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 6px 6px;
    border-color: transparent transparent rgba(0, 0, 0, 0.8);
  }
}

.left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(-8px);

  &::before {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
  }
}

.right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(8px);

  &::before {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-width: 6px 6px 6px 0;
    border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
