@import "App.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border: 0.5px solid #000;
  flex-shrink: 0;

  .text {
    color: #d2d2d2;
  }

  img {
    width: 100%;
  }

  &.hasHoverState:hover {
    outline: 3px solid $color-config-hover;
  }

  &.l {
    width: 122px;
    height: 192px;
    border-radius: 12px;
    overflow: hidden;
  }
  &.md {
    width: 88px;
    height: 134px;
    padding-top: 33px;
    padding-bottom: 38px;
    row-gap: 6px;
    .text {
      font-size: 16px;
      font-weight: 700;
    }
  }
  &.xs {
    width: 36px;
    height: 60px;
    border-radius: 3.6px;
    border: 0.18px solid #000000;
    background-color: #fff;
    row-gap: 5px;
    padding-top: 12px;
    padding-bottom: 16px;
    .text {
      font-size: 5px;
      font-weight: 700;
    }
  }
}

.emptyIcon {
  flex-shrink: 0;
}
