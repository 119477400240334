@import "App.scss";

.button {
  padding: 8px 16px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;

  &.primary {
    background-color: #8b1516;
    color: white;

    &:hover {
      background-color: #590e0f;
    }
  }

  &.secondary {
    background-color: white;
    color: $color-text-regular-main;

    &:hover {
      background-color: rgb(214, 212, 212);
    }
  }

  &.isLoading {
    border-color: #d2d2d2 !important;
    color: #d2d2d2 !important;
    background-color: #f6f6f6 !important;
  }

  &.outlined {
    background-color: white;
    color: $color-text-regular-main;
    border: 1px solid $color-text-regular-main;
    border-radius: 8px;

    &.danger {
      color: #a30a2a;
      border-color: #a30a2a;
    }

    &:hover {
      background-color: rgb(214, 212, 212);
    }
  }

  &.transparentOutlined {
    background: transparent;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 6px;
  }

  &.transparent {
    color: #c5c5c5;
    background-color: transparent;
    &:hover {
      background-color: #404040;
    }
  }

  &.xs {
    font-size: 12px;
    padding: 5px 10px;
  }
  &.s {
    font-size: 18px;
    font-weight: 500;
    padding: 6px 20px;
  }
  &.m {
    font-size: 18px;
    font-weight: 500;
    padding: 18px 40px;
  }
  &.l {
    font-weight: 500;
    font-size: 18px;
    padding: 12px 24px;
  }
  &.xl {
    font-size: 20px;
    padding: 12px 24px;
  }
  &.textBold {
    font-weight: 700;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}
