@import "App.scss";

.container {
  max-width: calc((100% / 3) - 24px);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.text {
  @include RegularTitleThinText;
  color: #404040;
  white-space-collapse: preserve-breaks;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #000;
}
