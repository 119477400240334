@import "App.scss";

.container {
  width: 162px;
  height: 164px;
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0px 0px 8px 0px #00000029;
  background-color: #292929;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    outline: 0.5px solid #008ecc;
  }

  .logoBlock {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #404040;
    background-color: #171717;
    border-radius: 12px;

    .logo {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      object-fit: cover;
    }
    .txt {
      font-size: 16px;
      font-weight: 400;
      color: #646464;
    }
  }
  .name {
    color: #c5c5c5;
    @include SmallText;
  }
}
