@import "App.scss";

.header {
  position: sticky;
  top: 20px;
  margin: 20px 20px 0 20px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0 32px 0 60px;
  height: 76px;
  transition: background-color 0.3s;
  z-index: $z-index-3;

  &.transparent {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.logo {
  margin-right: auto;
  cursor: pointer;
}

.navigation {
  display: flex;
  height: 100%;
  align-items: center;
}

.navItem {
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.3s;
  height: 100%;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  cursor: pointer;

  &:hover {
    color: $color-primary-active;
  }
}

.navItem:last-child {
  margin-right: 0;
}

.marketSector {
  width: 200px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  text-transform: capitalize;
}

.headerSectionItem {
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  &:hover {
    background-color: #282a2ff7;
  }
}

.dropdownSecTitle {
  @include RegularTitleText;
  display: flex;
  align-items: center;
  height: fit-content;
  color: #fff;
  padding: 20px 24px;
  text-decoration: none;
  min-height: 60px;
  text-transform: capitalize;

  &:hover {
    color: $color-primary-active;
  }
}

.downloadItemContainer {
  margin-left: 32px;
}

.popoverItem {
  @include BigTitleBoldText;
  color: #000000;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 4px;

  &:hover {
    color: $color-primary-active;
  }
}

.logoTooltipAnchor {
  margin-right: auto !important;
}

.sectionDialogPaper {
  overflow: hidden !important;
  padding: 0 !important;
}
