@import "App.scss";

.dropdownItem {
  position: relative;
  height: 100%;
  &:hover {
    background-color: #282a2ff7;
  }
}

.dropdownButton {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  text-transform: capitalize;

  &:hover,
  &:focus,
  &.active {
    color: $color-primary-active;

    .icon {
      transform: rotate(180deg);
    }
  }
  .icon {
    pointer-events: none;
  }
}

.icon {
  margin-left: 8px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.iconOpen {
  transform: rotate(180deg);
  color: $color-primary-active;
}

.dropdownBG {
  position: fixed;
  background-color: #282a2e;
  width: calc(100vw - 40px);
}

.dropdownContent {
  display: flex;
  position: fixed;
  background-color: #282a2e;
  width: calc(100vw - 40px);
  left: 0;
  top: 90px;
  margin: 0 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: $z-index-5;
  height: fit-content;

  &.hasLeftPadding {
    padding-left: 500px;
  }

  .dropdownItem:hover &,
  .dropdownItem:focus-within & {
    display: flex;
  }
}
