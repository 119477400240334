@import "App.scss";

.container {
  margin-left: -120px;
  height: 11px;
  position: relative;
  overflow: visible;
  background-color: $color-red-dark;
}

.wordBlock {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  height: 45px;
  width: 250px;
  position: absolute;
  top: -40px;
  left: calc(50% - 125px);
  z-index: $z-index-1;
  overflow: hidden;
  border-radius: 70px 70px 0 0;
  cursor: pointer;
}
