.container {
  position: relative;
}

.title {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.leftWavesIcon {
  position: absolute;
  left: -20px;
  top: -20px;
}
