@import "App.scss";

.container {
  position: absolute;
  width: fit-content;
  min-width: 202px;
  height: 32px;
  border-radius: 30px;
  box-shadow: 0px 16px 30px 0px #0000001f;
  display: flex;
  padding-right: 12px;
  align-items: center;
  column-gap: 12px;
  background-color: #fff;

  &.standardFeature {
    position: static;
    width: auto;

    .text {
      font-size: 14px;
      margin-left: unset !important;
      flex: 1;
    }
  }

  &.withForward {
    cursor: pointer;
  }

  &.leftPaddingOnly {
    padding-right: unset;
    padding-left: 12px;

    .text {
      margin-right: auto;
    }
  }

  &.disabled {
    opacity: 0.3;
  }
}

.text {
  font-size: 14px;
  font-weight: 500;
  color: #040637;
  margin: 0;
}

.dashLine {
  @include BigLaptop {
    display: none;
  }
}