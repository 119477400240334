@import "App.scss";

.container {
  position: relative;
  width: 186px;
  height: 182px;
  background-color: #f6f6f6;
  border: 2.4px dashed #d2d2d2;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .order {
    position: absolute;
    left: calc(50% - 20px);
    bottom: -20px;
    width: 40px;
    height: 40px;
    border: 2px solid #d2d2d2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #2e2e2e;
    font-size: 24px;
    font-weight: 700;
  }
}
