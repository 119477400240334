.dialogContainer {
  h2 {
    color: #c5c5c5;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .submitBtn {
    width: 100%;
  }
}
