@import "App.scss";

.container {
  width: calc(33.333% - 40px);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  row-gap: 16px;
  box-shadow: 0px 0px 18px 0px #0000001a;
  background-color: #fff;
  padding: 16px;
  text-decoration: none;
}

.imgBlock {
  width: 100%;
  padding-top: 100%;
  position: relative;
  max-width: 340px;


  img {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.name {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.desc {
  margin: 0;
  color: #000000;
  @include RegularTitleThinText;
}
