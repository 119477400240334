@import "App.scss";

.formControl {
  position: relative;
}

.select {
  background-color: $color-form-elm-bg-dark;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  border: $border-form-elm-dark;

  &.md {
    height: 56px;
  }

  &:focus {
    .notchedOutline {
      border: none !important;
    }
  }
}

.selectLabel {
  color: $color-outline-dark;
}

.menuPaper {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  background-color: $color-form-elm-bg-dark !important;
  border-radius: 8px;
  border: $border-form-elm-dark;
}

.menuItem {
  color: $color-outline-dark !important;
  background-color: $color-form-elm-bg-dark !important;
  padding: 8px 16px !important;

  &:hover {
    background-color: #444 !important;
  }
}

.placeholderContainer {
  display: flex;
  align-items: center;
}

.placeholder {
  color: $color-outline-dark; /* Change this to set the placeholder color */
  margin-right: auto;
}

.selectedValueContainer {
  display: flex;
  align-items: center;
}

.selectedValue {
  color: #fff;
}

.infoIcon {
  margin-left: auto;
  margin-right: 16px;
}

.customIcon {
  position: absolute;
  right: 16px;
  pointer-events: none;
}

.notchedOutline {
  border-color: $color-outline-dark !important; /* Change this to set the outline color */
}

.radio {
  color: #647886 !important; /* Non-selected radio button color */
}

.checkedRadio {
  color: $color-config-hover !important; /* Selected radio button color */
}

.tooltipContent {
  width: 350px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .title {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
  }
  .contentTxt {
    color: #cdcdcd;
    font-size: 400;
    font-weight: 14px;
    text-align: center;
  }
}
