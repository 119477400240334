@import "App.scss";

.container {
  position: absolute;
  z-index: 99999;
  top: 360px;
  right: -177px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 24px 15px 13px;
  border-radius: 50px 0px 0px 50px;
  column-gap: 8px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transform: translateX(-177px);
  }
}
