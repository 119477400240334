@import "App.scss";

.scrollToTopButton {
  background: none;
  border: none;
  width: fit-content;
  height: fit-content;
  position: fixed;
  bottom: 200px;
  right: 120px;
  z-index: $z-index-2;
  cursor: pointer;
}
