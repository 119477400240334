@import "App.scss";

.container {
  width: 100%;
  padding: 20px 120px;
  background-color: $bg-color-dark-2;
  color: $color-text-gray-2;
  display: flex;
  flex-direction: column;
  row-gap: 76px;
}

.addressesBlock {
  display: flex;
  gap: 72px;
  flex-wrap: wrap;
}

.addressColumn {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.flagRow {
  margin-bottom: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-transform: uppercase;
}

.row {
  @include RegularTitleThinText;
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  text-decoration: none;
  color: inherit;
}

.generalFootBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.othersSayingBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 48px;
  .sectionTitle {
    @include LargeTitleText;
    color: #fff;
    text-align: center;
  }
}
