@import "App.scss";

.container {
  display: flex;
  flex-direction: column;
  color: $color-text-dark-1;
  max-width: 356px;
  width: 100%;
  margin-top: 32px;
  padding-left: 10px;

  .section {
    @include BigTitleBoldText;
    line-height: 24px;
  }

  .title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    margin: 6px 0 32px;
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    list-style-type: disc;
    padding-left: 17px;
    .paragraph {
      font-size: 16px;
      font-weight: 400;
      color: #404040;
    }
  }
}