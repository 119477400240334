@import "App.scss";

.primary {
  border-radius: 20px !important;
  padding: 26px 24px 26px 24px;
}

.s {
  width: 30vw;
  min-width: 300px;
}

.md {
  width: 50vw;
  min-width: 500px;
}

.actionsBlock {
  display: flex;
  column-gap: 16px;
  button {
    flex: 1;
    margin-left: unset;
  }
}

.lg {
  width: 80vw;
  min-width: 800px;
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #757575;
}

.title {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #000;
  align-self: center;
}

.description {
  text-align: center;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: $color-text-regular-main !important;
}
