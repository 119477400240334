@import "App.scss";

.container {
  color: #404040;
  background-color: #fff;
}

.characteristicsSec {
  display: flex;
  flex-direction: column;
  row-gap: 66px;
  padding: 32px 100px 146px 100px;
}

.generalDesc {
  @include BigTitleText;
  text-align: center;
}

.infos {
  display: flex;
  flex-wrap: wrap;
  padding: 96px 32px 96px 58px;
  column-gap: 30px;
  row-gap: 57px;
  background-color: #fff;
}

.systemOptions {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: #fff;
}

.optionsBg {
  position: absolute;
  top: -140px;
  font-size: 360px;
  font-weight: 700;
}

.sysOptsContent {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.sysOptsColumn {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 24px 74px;
  background-color: #f3f6f585;
  min-width: 600px;

  .sysOptionRow {
    display: flex;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;

    svg {
      flex-shrink: 0;
    }
  }
}

.colTitleBlock {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  .colTitle {
    font-size: 20px;
    font-weight: 600;
  }
}

.whyChooseUs {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #fff;

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.itemsSection {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .otherMarketsContent {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
}
