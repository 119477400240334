@import "App.scss";

.container {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

.featuresBlock {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  background-color: #eff2f1;
}

.features {
  position: relative;
  width: 100%;
  background-image: url("../../assets/backgrounds/standard_features_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 96px;

  .features_block {
    display: flex;
    margin-top: 40px;
    column-gap: 24px;
    width: 100%;

    .features_column {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      &:first-child {
        max-width: 475px;
      }

      &:nth-child(2) {
        flex: 1;
        max-width: 60%;
      }
    }
  }

  @include SmallNotebook {
    height: 500px;
    width: 1000px;

    &>div:nth-child(2) {
      top: 22px !important;
      left: 100px !important;
    }

    &>div:nth-child(3) {
      top: 102px !important;
      left: 70px !important;
    }

    &>div:nth-child(4) {
      top: 182px !important;
      left: 100px !important;
    }

    &>div:nth-child(5) {
      top: 262px !important;
      left: 70px !important;
    }

    &>div:nth-child(6) {
      top: 342px !important;
      left: 160px !important;
    }

    &>div:nth-child(7) {
      top: 422px !important;
      left: 240px !important;
    }

    &>div:nth-child(8) {
      top: 342px !important;
      left: 640px !important;
    }

    &>div:nth-child(9) {
      top: 262px !important;
      left: 664px !important;
    }

    &>div:nth-child(10) {
      top: 182px !important;
      left: 686px !important;
    }

    &>div:nth-child(11) {
      top: 102px !important;
      left: 740px !important;
    }

    &>div:nth-child(12) {
      top: 22px !important;
      left: 650px !important;
    }
  }
}

.title {
  font-weight: 600;
  text-align: center;
  font-size: 48px;
  position: relative;

  .titleIcon {
    position: absolute;
    top: -24px;
    right: -16px;
  }
}

.infos {
  display: flex;
  flex-wrap: wrap;
  padding: 96px;
  column-gap: 35px;
  row-gap: 57px;
  background-color: #fff;
}

.systemOptions {
  max-width: 1440px;
  height: 878px;
  width: 100%;
  position: relative;
  padding: 0 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  row-gap: 40px;
  background-color: $bg-color-sec-1;

  .prodImgContainer {
    width: 640px;
    height: 640px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    z-index: 9;
    border: 8px solid #ebebeb;
    outline: 8px solid #f3f3f3;

    @include BigLaptop {
      width: 480px;
      height: 480px;
    }

    .productImg {
      height: 100%;
      max-height: 440px;
      max-width: 440px;
      object-fit: contain;
      z-index: 99;
      cursor: pointer;
    }
  }
}

.productImgInModal {
  height: 90dvh;
}

.optionsBg {
  position: absolute;
  top: -140px;
  font-size: 360px;
  font-weight: 700;
}

.sectionTitleBlock {
  position: relative;
}

.sectionTitle {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.leftWavesIcon {
  position: absolute;
  left: -20px;
  top: -20px;
}

.sysOptsContent {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sysOptsColumn {
  display: flex;
  flex-direction: column;
  column-gap: 32px;
  row-gap: 8px;
  padding: 24px 74px;
  background-color: #f3f6f585;
  min-width: 600px;
  max-width: 44%;

  .sysOptionRow {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}

.colTitleBlock {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  .colTitle {
    font-size: 20px;
    font-weight: 600;
  }
}

.section {
  position: relative;
  padding: 147px 124px 80px 121px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: #fff;
}

.otherChoiceContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}