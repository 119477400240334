.container {
  width: 332px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  column-gap: 16px;

  &.empty {
    width: 243px;
  }
  &.autoWidth {
    width: auto;
  }

  .rowLine {
    display: flex;
    column-gap: 16px;
    height: 9px;
    border-radius: 5;
    background-color: #e5e5e5;
  }
}
