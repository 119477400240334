@import "App.scss";

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
}

.errorContainer {
  position: absolute;
  top: -30px;
  right: 0;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  gap: 6px !important;
  color: #ce2424;
}

.mainInputElm {
  margin-left: 22px !important;
  color: #c5c5c5 !important;

  &.strongDark {
    background-color: $color-form-elm-bg-dark;
    color: #fff !important;
    border: $border-form-elm-dark;
    margin-left: 0 !important;
    border-radius: 8px;
  }

  &.hasError {
    color: #ce2424 !important;
  }
}

.errorIconContainer {
  height: 24px;
}

.errorText {
  font-size: 12px;
}
